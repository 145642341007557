import React from 'react'
import logoCredACI from '../assets/imgs/logo-w.png'
import logosB from '../assets/imgs/logos.png'

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            O Microcrédito ACI NH é uma solução para auxiliar prestadores de
            serviços e micro e pequenos empresários a transformarem os seus
            negócios.
          </p>
          <p>
            O objetivo é <strong>facilitar o acesso ao crédito</strong>,
            possibilitando investimentos em infraestrutura, planejamento de
            ampliação, melhorias de gestão e outras necessidades da empresa.
          </p>
          <p>
            Essa é uma união de esforços entre a ACI Novo Hamburgo, Sicredi
            Pioneira, RSGaranti e Sebrae para fortalecer a economia local!
          </p>
        </div>

        <div className="logos">
          <img
            className="credLogo"
            src={logoCredACI}
            alt="Logotipo da CredACI"
          />
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos Sicredi, Sebrae, RS Garanti"
          />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionA
