let agencias = [
    {
        id: 473,
        name: 'Centro',
    }, 
    {
        id: 1628,
        name: 'Canudos',
    },
    {
        id: 465,
        name: 'Feevale',
    },
    {
        id: 178,
        name: 'Ouro Branco',
    },
    {
        id: 471,
        name: 'Lomba Grande',
    },
    
]
export {agencias}